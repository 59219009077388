import React from "react"
import { StaticQuery, graphql } from "gatsby"
// import {FaEnvelope, FaLocationArrow, FaFacebook, FaLinkedin} from "react-icons/fa"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { FaChevronRight } from "react-icons/fa"

// import SiteImg1 from "../assets/img/component-site.png"



const PrivacyPage = () => (
  <StaticQuery
    query={graphql`
      query{
        allStrapiPages{
          edges {
            node {
              PageTitle
            }
          }
        }
      }
    `}

    render={data => (
      
    <Layout pageData={""}>
        <SEO title="No win no fee" />


        
        <section className="contact-form-section">
          <h1>No Win No Fee Claims</h1>
          
          <div className="full-width-paragraph">

          
         
            <p class="grey-paragraph-faq" >With an increase in legal fees and increased expenditure in claim process to follow, people are often reluctant to ask for their rights and do not want to file a claim at all.<br/>
            However, it’s just about to understand that you have rights to claim compensation if it’s not your fault and get financial or medical compensation you deserve on No Win No Fee claims basis.</p>

            <h3 class="title-grey-faq-information">What is no win no fee accident claim solicitors?</h3>
            <p class="grey-paragraph-faq" >It is an understanding agreement between a solicitor and client which shows that client will only pay when there is a Winning situation and their claim is a success. Here the risk factor falls more on the solicitor than the client who feels secure and safe in any case. This feature compensates the risk ratio in the event of claim and client can seek all sorts of help and discuss their happenings freely with the solicitor. <br/>
In UK an agreement, CFA is signed that assures advocacy and proof of litigation. In CFA agreement the charges of the solicitor depends upon the outcome and result of the case.</p>

        <h3 class="title-grey-faq-information">Advantages of No Win No Fee Accident Claims</h3>
        <p class="grey-paragraph-faq" >Victims of accidents or any kind of personal injuries can get significant advantage by filing a claim without the fear of expense or any losses. <br/>
                    <FaChevronRight></FaChevronRight> It is a way to help victims who does not have funds to claim.<br/>
                    <FaChevronRight></FaChevronRight> The solicitor holds all the risk.<br/>
                    <FaChevronRight></FaChevronRight> When your solicitor agrees to support on No Win No Fee basis, this assures a solid outcome and thereby it becomes less distressing for the  claimant. He/She is confident about the claim process and trusts the solicitor for all the expenses.<br/>
        </p>

            
            <h3 class="title-grey-faq-information">How a solicitor gets paid if a ‘no win no fee’ case is successful</h3>
            <p class="grey-paragraph-faq" >If your solicitor is successful in winning the claim process, you are entitled to receive 100% of the awarded compensation, Now, out of that  compensation amount received you can pay your solicitor fees and cost as agreed upon which usually is capped at 25%. So it’s in the best interest of the solicitor to fight for the highest amount possible. At SEO Developments our panel solicitors will only take a case on if it has more than 50% chance of winning.<br/>
            It is important that all essential points of agreements are written down as proof in writing before the trail and the objective is clear at both ends without  the risk of losing. <br/>
           
            SEO Developments can always help you find a best solicitor in UK whom you can trust for the complete process and they can guide you the best with your claim. At SEO Developments Support line, our solicitors work on ‘no win, no fee’ basis. We also offer free guide and support services and help the victims with all  kinds of injury and accident claims. In UK we have the best expert solicitors aligned with us that helps the claimant make their free will choice.
            </p>
          </div>

        </section>



      </Layout>

    )}
  
  />
)

export default PrivacyPage
